
import { OrderModule } from '@/store/modules/order';
// import moment from 'moment';
import { Vue, Component } from 'vue-property-decorator';
import { storageKey } from '@/enums/localStorage';
import { getOrderCount } from '@/api/order';
import { ThisTypedComponentOptionsWithArrayProps } from 'vue/types/options';

@Component({
  name: 'OrderCart',
})

export default class extends Vue {
  mounted() {
    this.getOrderCount();
  }

  private loading = false;

  private orderForm = OrderModule.orderForm;

  private orderCountForm: any = '';

  private selectedShop = JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace));

  private getOrderCount() {
    this.loading = true;
    getOrderCount(this.selectedShop.idx, OrderModule.orderForm).then((res) => {
      this.orderCountForm = res.data;
    });
    this.loading = false;
  }

  private handleOrder() {
      this.$router.push({ name: 'Payment' });
  }

  private handleProduct(item: any, weekNum: number, dayNum: number, productNum: number) {
    const dayStock: number = this.selectedShop.maxHoldCnt - this.orderCountForm.week[weekNum].day[dayNum].orderCount;
    const products: any = this.orderForm.week[weekNum].day[dayNum].products;
    const keys: any = Object.keys(products);
    let sum = 0;
    keys.forEach((key: any) => {
      sum += Number(products[key].count);
    });
    if (sum > dayStock) {
      this.$message.info('구매 가능한 수량이 초과했습니다.');
      item.count = Number(dayStock - (sum - this.orderForm.week[weekNum].day[dayNum].products[productNum].count));
    }
      OrderModule.setProduct({
        ...item,
        weekNum: [weekNum],
        dayNum: [dayNum],
      });
  }

  private handleCancel(item: any, weekNum: number, dayNum: number) {
    OrderModule.cancelProduct({
      ...item,
      weekNum: [weekNum],
      dayNum: [dayNum],
    });
  }
}
